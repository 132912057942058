import React, { useState, useEffect } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import { MobileMenu } from "../_components/navigation/mobile-menu";

import CommunityMenu from "../_components/navigation/community-menu";
import { appService, seedServices, communityService } from "../_services";

import ico_back from "../_images/ico-back.svg";
import ico_joined from "../_images/ico-joined.svg";
import { Link, useNavigate } from "@reach/router";
import { MembersGroup } from "../_components/modals/members-group";
import { CommunityPostsGroup } from "../_components/lists/community-post-group";

const GroupDetailPage = ({ slug }) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const [internal, setInternal] = useState([]);
  const [submitJoin, setSubmitJoin] = useState(false);

  const [userGroup, setUserGroup] = useState("");
  const navigate = useNavigate();
  const link = `/${lang}/${t("url.groups")}`;

  const [members, setMembers] = useState([]);

  let data;

  localStorage.setItem("group_id", JSON.stringify(slug));

  useEffect(() => {
    /** Call Services  */
    seedServices
      .getInternalGroup(lang, slug)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setInternal(response);
        }
      })
      .catch(console.log);
  }, [lang, setInternal, slug]);

  useEffect(() => {
    seedServices
      .getInfoGroup(lang, slug)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          response.articles[0] !== undefined &&
            setUserGroup(response?.articles[0].id);
        }
      });
  }, [lang, slug]);

  useEffect(() => {
    /** Call Services  */
    appService
      .getMembers(lang, slug)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setMembers(response);
        }
      })
      .then(() => (data = members.filter((i) => i.uid)))
      .catch(console.log);
  }, [lang, setMembers]);

  const submitJoinButon = (e) => {
    e.preventDefault();
    setSubmitJoin(true);
    const { id } = JSON.parse(localStorage.getItem("me"));

    communityService.postNotificationUser(
      "group",
      null,
      slug,
      id
    );

    communityService
      .notificationGroup(slug, "group")
      .then((res) => res.json())
      .catch(console.log);
  };

  const joinMe = () => {
    const { id } = JSON.parse(localStorage.getItem("me"));
    appService
      .postJoinGroup({ id, slug })
      .then((res) => res.json())
      .then(() => {
        communityService.postNotificationUser(
          "group",
          null,
          slug,
          id
        );
      })
      .then(() => {

        window.location.reload();
        // if (localStorage.getItem("exist-member")){
        //   localStorage.removeItem("exist-member");
        // }
      })
      .catch(console.log);
  };

  const leaveGroup = () => {
    appService
      .deleteLeaveGroup(slug, userGroup)

      .then(() => {
        navigate(link);
      })
      .then(() => {
        window.location.reload();
      })
      .catch(console.log);
    // window.location.reload();
    if (localStorage.getItem("exist-member")) {
      localStorage.setItem("exist-member", "undefined");
    }
  };

  const logo = internal[0] !== undefined ? internal[0].field_logo : "";
  const label = internal[0] !== undefined ? internal[0].label : "";
  const description =
    internal[0] !== undefined ? internal[0].field_description : "";
  const rules = internal[0] !== undefined ? internal[0].field_rules : "";
  const type = internal[0] !== undefined ? internal[0].type : "";

  const exist =
    localStorage.getItem("exist-member") !== "undefined" ? true : false;
  // const exist =  false ;
  const memberLength = localStorage.getItem("memberLength");

  const groupSend = false;

  return (
    <Layout withFade={true}>
      <SEO lang={lang} title={t("groups.tab_discover")} />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="profile__page feed">
          <div className="container d-flex flex-wrap">
            <div className="profile__menu sticky col-lg-2 col-md-3">
              <CommunityMenu selected="groups" />
            </div>
            <div className="col-lg-10 col-md-9">
              {/* Header Box */}
              <div className="profile__header group__details">
                <Link
                  to={`/${lang}/${t("groups.ttl_black")}`}
                  className="back__btn"
                >
                  <img src={ico_back} alt="Back" />
                  {t("groups.title")}
                </Link>
                <div className="public__profile__box d-flex justify-content-between col-12">
                  <div className="d-flex">
                    <div className="profile__main__image col-lg-4 col-md-4">
                      <img
                        src={logo}
                        // src="/images/temp/image-detail-group.png"
                        alt="Group"
                      />
                    </div>
                    <div className="profile__information col-lg-8 col-md-8">
                      <div className="profile__name d-flex">
                        <h1 className="group__name">{label}</h1>
                      </div>
                      <span className="group__description">{description}</span>
                      <div className="group__members d-flex align-items-center justify-content-center justify-content-sm-center justify-content-md-start justify-content-lg-start">
                        <div className="joined">
                          <img src={ico_joined} alt="Joined" />
                          {t("groups.united")}
                        </div>
                        <div className="members__counter">
                          {memberLength} {t("groups.members")}
                        </div>
                      </div>

                      {type === "Group Public" && !exist && (
                        <button
                          className="button-blue general-button"
                          onClick={joinMe}
                        >
                          {t("groups.join_me")}
                        </button>
                      )}
                      {type === "Group private" && !exist && (
                        <button
                          className={`button-blue general-button  ${
                            submitJoin && "button-gray"
                          }`}
                          onClick={submitJoinButon}
                        >
                          {submitJoin
                            ? `${t("groups.pending")}`
                            : `${t("groups.request")}`}
                        </button>
                      )}
                      {exist && (
                        <button
                          className="button-gray-light general-button"
                          onClick={leaveGroup}
                        >
                          {t("groups.leave")}
                        </button>
                      )}
                      {groupSend && (
                        <div className="button-group-send">
                          <button
                            className="button-blue general-button"
                            onClick={joinMe}
                          >
                            {t("groups.join_me")}
                          </button>
                          <button className="button-blue-light general-button">
                            {t("groups.decline")}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-wrap group__community">
                {/* Community Feed */}
                <div className="profile__body col-lg-7 col-md-12 pl-0">
                  <div className="feeds__community">
                    <CommunityPostsGroup
                      group="true"
                      idGroup={slug}
                      members={members}
                    />
                  </div>
                </div>

                {/* Sidebar */}
                <div className="community__right__box col-lg-5 col-md-9">
                  <div className="groups__maylike">
                    <MembersGroup id_group={slug} />

                    <div className="groups__description__box">
                      <div className="d-flex">
                        <div className="profile__main__image col-3 pl-0 pr-0">
                          <img
                            // src="/images/temp/image-detail-group.png"
                            src={logo}
                            alt="Group"
                          />
                        </div>
                        <div className="profile__information col-9">
                          <div className="profile__name d-flex">
                            <h2>{t("groups.description")}</h2>
                          </div>
                        </div>
                      </div>
                      <div className="groups__description__txt">
                        <p>{description}</p>
                      </div>
                    </div>
                    <div className="group__rules__box">
                      <h2>{t("groups.rules")}</h2>
                      {rules}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <MobileMenu selected="community" withFade={true} />
    </Layout>
  );
};

export default GroupDetailPage;
