import { authHeader } from "../_helpers";
import { appConstants } from "../_constants";

export const eventService = {
  getEventsUpcommig,
  getEventsFeatured,
  getEventsRecent,
  getInfoEvent,
  registerEventForm,
  getMembersEvent,
  getSearchRecentEvent,
  getSearchUpcommingEvent,
  getLastEvent
};

function getEventsUpcommig(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/events-upcomming`,
    requestOptions
  );
}

function getEventsFeatured(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/events-featured`,
    requestOptions
  );
}

function getEventsRecent(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${appConstants.API}/${lang}/v1/events-recent`, requestOptions);
}

function getInfoEvent(lang, slug, page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/events?alias=/${slug}&page=${page}`,
    requestOptions
  );
}

function registerEventForm(lang, data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${appConstants.API}/${lang}/users-register-event`,
    requestOptions
  ).then(requestOptions);
}

function getMembersEvent(lang, idEvent) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/events-user/${idEvent}`,
    requestOptions
  );
}

function getSearchRecentEvent(lang, filters) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let query = "";
  const { q, d, c, b } = filters;
  if (q.length) {
    query += `name=${q}`;
  }
  if (d.length) {
    query += `date=${d}`;
  }
  if (b.length) {
    query += `brand=${b}`;
  }
  if (c.length) {
    query += `category=${c}`;
  }
  const url = `${appConstants.API}/${lang}/v1/events-recent?${query}`;
  return fetch(url, requestOptions);
}

function getSearchUpcommingEvent(lang, filters) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let query = "";
  const { q, d, c, b } = filters;
  if (q.length) {
    query += `name=${q}`;
  }
  if (d.length) {
    query += `date=${d}`;
  }
  if (b.length) {
    query += `brand=${b}`;
  }
  if (c.length) {
    query += `category=${c}`;
  }
  const url = `${appConstants.API}/${lang}/v1/events-upcomming?${query}`;
  return fetch(url, requestOptions);
}

function getLastEvent(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/last-event`,
    requestOptions
  );
}