import { authHeader } from "../_helpers";
import { appConstants } from "../_constants";

export const appService = {
  initConfig,
  getHero,
  getMenu,
  // getBanners,
  postNewsletter,
  postContact,
  getInterest,
  getPolitics,
  postGroup,
  getMembers,
  postMembers,
  getMoreGroups,
  getMoreGroups2,
  postJoinGroup,
  getArticles,
  getMembersGroup,
  getNotification,
  deleteNotification,
  deleteLeaveGroup,
  getAds,
  getNotificationUser,
  deleteNotificationUser
};

function initConfig(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${appConstants.API}/${lang}/v1/categories`, requestOptions);
}

function getHero(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${appConstants.API}/${lang}/v1/hero-banner`, requestOptions);
}

function getMenu(lang, id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    // `${appConstants.API}/${lang}/decoupled_kit/menu/${id}`,
    `${appConstants.API}/${lang}/menu/${id}`,
    requestOptions
  );
}

// function getBanners(lang) {
//   const requestOptions = {
//     method: "GET",
//     headers: authHeader(),
//   };

//   return fetch(`${appConstants.API}/${lang}/v1/ads`, requestOptions);
// }

function postNewsletter(lang, email, categories) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/hal+json" },
    body: JSON.stringify({
      webform_id: "newsletter",
      name: "correoUusario",
      email: email,
      preferences: categories,
    }),
  };

  return fetch(
    `${appConstants.API}/${lang}/webform_rest/submit?_format=hal_json`,
    requestOptions
  );
}

function postContact(lang, { name, email, phone, message, subject }) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      webform_id: "contact",
      name: name,
      email: email,
      phone: phone,
      message: message,
      subject: subject,
    }),
    credentials: "same-origin",
  };

  return fetch(
    `${appConstants.API}/${lang}/webform_rest/submit?_format=json`,
    requestOptions
  );
}

function getInterest(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${appConstants.API}/${lang}/v1/interest`, requestOptions);
}

function getPolitics(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${appConstants.API}/${lang}/v1/politics`, requestOptions);
}

function postGroup(lang, { image_id, name, description, rules, stateGroup }) {
  const payload = stateGroup[0].target_id === "group";

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/hal+json",
    },
    body: JSON.stringify({
      _links: {
        type: {
          href: payload
            ? `http://${appConstants.LOCATION}/rest/type/group/group`
            : `http://${appConstants.LOCATION}/rest/type/group/group_private`,
        },
      },
      type: stateGroup,
      label: [{ value: name }],
      field_logo: [{ target_id: image_id }],
      field_rules: [{ value: rules }],
      field_description: [{ value: description }],
    }),
    credentials: "same-origin",
  };

  return fetch(
    `${appConstants.API}/entity/group?_format=hal_json`,
    requestOptions
  );
}

function getMembers(lang, id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/group/${id}/members/json`,
    requestOptions
  );
}

function postMembers({ group_id, member_id }) {
  // Promise.all(
  //   member_id.map(async function (e) {
  //     const requestOptions = {
  //       method: "POST",
  //       headers: {
  //         ...authHeader(),
  //         "Content-Type": "application/hal+json",
  //         // Authorization: "Basic dGZhZG1pbjphaWkyOFhHYUhMeGFTR1I=",
  //       },
  //       body: JSON.stringify({
  //         _links: {
  //           type: {
  //             href: `http://${appConstants.LOCATION}/rest/type/group_content/group-group_membership`,
  //           },
  //         },
  //         type: [{ target_id: "group-group_membership" }],
  //         gid: [{ target_id: group_id }],
  //         entity_id: [{ target_id: Number(e) }],
  //       }),
  //       credentials: "same-origin",
  //       timeout: 5000,
  //     };
  //     const res = await fetch(
  //       `${appConstants.API}/entity/group_content?_format=hal_json`,
  //       requestOptions,
  //     );
  //     return console.log(res);
  //   })
  // );

  const apiUrl = `${appConstants.API}/entity/group_content?_format=hal_json`; // Endpoint de la API
  const maxRequests = 2; // Máximo número de solicitudes que se pueden realizar simultáneamente
  const requestInterval = 1000; // Intervalo de tiempo entre cada solicitud (en milisegundos)

  function sendRequests(member_id) {
    return new Promise((resolve, reject) => {
      const results = [];
      let currentIndex = 0;
      let activeRequests = 0;

      function sendNextRequest() {
        if (currentIndex === member_id.length) {
          resolve(results);
          return;
        }

        if (activeRequests < maxRequests) {
          activeRequests++;
          let requestBody = member_id[currentIndex];
          currentIndex++;

          fetch(apiUrl, {
            method: "POST",
            headers: {
              ...authHeader(),
              "Content-Type": "application/hal+json",
            },
            body: JSON.stringify({
              _links: {
                type: {
                  href: `http://${appConstants.LOCATION}/rest/type/group_content/group-group_membership`,
                },
              },
              type: [{ target_id: "group-group_membership" }],
              gid: [{ target_id: group_id }],
              entity_id: [{ target_id: Number(requestBody) }],
            }),
            credentials: "same-origin",
          })
            .then((response) => {
              activeRequests--;
              if (response.ok) {
                return response.json();
              } else {
                throw new Error("Response failed");
              }
            })
            .then((data) => {
              results.push(data);
              sendNextRequest();
            })
            .catch((error) => {
              activeRequests--;
              reject(error);
            });
        } else {
          setTimeout(sendNextRequest, requestInterval);
        }
      }

      sendNextRequest();
    });
  }

  sendRequests(member_id)
    .then((results) => {
      console.log(results);
      // Manejar la respuesta de cada solicitud aquí
    })
    .catch((error) => {
      console.log(error);
      // Manejar el error aquí
    });
}

function getMoreGroups(lang, blocks) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/paginador?page=${blocks}`,
    requestOptions
  );
}

function getMoreGroups2(lang, blocks) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/paginador?page=${blocks}`,
    requestOptions
  );
}

function postJoinGroup({ id, slug }) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/hal+json",
      // "Authorization": "Basic dGZhZG1pbjphaWkyOFhHYUhMeGFTR1I="
    },
    body: JSON.stringify({
      _links: {
        type: {
          href: `http://${appConstants.LOCATION}/rest/type/group_content/group-group_membership`,
        },
      },
      type: [{ target_id: "group-group_membership" }],
      gid: [{ target_id: slug }],
      entity_id: [{ target_id: id }],
      group_roles: [
        {
          target_id: "group-member_group",
        },
      ],
    }),
    credentials: "same-origin",
  };
  return fetch(
    `${appConstants.API}/entity/group_content?_format=hal_json`,
    requestOptions
  );
}

function getArticles(lang, id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/group-views/${id}`,
    requestOptions
  );
}
function getMembersGroup(lang, id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/group-member/${id}`,
    requestOptions
  );
}

function getNotification(lang, uid) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/notifications?uid=${uid}&uid_group=${uid}/`,
    requestOptions
  );
}

function deleteNotification(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/admin/structure/notification/${id}?_format=hal_json`,
    requestOptions
  );
}

function deleteLeaveGroup(id, usr) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
      Cookie:
        "SESSd78e710e2850472da064984a0f00a7e4=o1jKy08O7QsGX9m9u9xWOSRfGvhCYdveQhQNlQ2Pro4",
    },
  };

  return fetch(
    `${appConstants.API}/group/${id}/content/${usr}?_format=json`,
    requestOptions
  );
}

// get ads
function getAds(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/advertisement`,
    requestOptions
  );
}

// New service notification
function getNotificationUser(uid) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/custom-notifications/get/${uid}/`,
    requestOptions
  );
}

function deleteNotificationUser(uid) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/custom-notifications/delete/${uid}/`,
    requestOptions
  );
}