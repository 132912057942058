import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { url, path } from "../../_helpers";
import { connect } from "react-redux";

import ico_interesting_counter from "../../_images/ico-interesting-counter.svg";
import ico_interesting from "../../_images/feed/like.png";
import ico_interesting_like from "../../_images/ico-interesting-like.svg";
import ico_comments from "../../_images/feed/comment.png";
import ico_toolbar from "../../_images/feed/share.png";
import ico_bigstar from "../../_images/ico-bigstar.svg";
import { Comments } from "../contents/comments";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { communityService } from "../../_services";

const PostReactions = ({
  favorites,
  type,
  post,
  qualification,
  actions,
  like,
}) => {
  //Language hooks
  const { t } = useTranslation("common");
  const shareUrl = path(url(post.url));
  const [likes, setLikes] = useState(Number("0" + post.likes));
  const [liked, setLiked] = useState(false);
  const [liking, setLiking] = useState(false);
  const [likeId, setLikeId] = useState(0);
  const [commenting, setCommenting] = useState(false);

  const [onLikes, setOnLikes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation("common");
  const lang = i18n.language;
  const [page, setPage] = useState(post.id);
  const pruebas = shareUrl.split("//e");
  const newUrl = `${pruebas[0]}/${lang}/${post.id}`;

  /**
   * Set initial state
   */
  useEffect(() => {
    if (favorites) {
      const favorite = favorites.filter((item) => item.entity == post.id);
      if (favorite.length > 0) {
        setLiked(true);
        setLikeId(favorite[0].id);
      }
    }

    return () => {
      setLiked(false);
      setLikeId(0);
    };
  }, [post, favorites]);

  useEffect(() => {
    fetchResults(lang, page);
  }, [lang, page]);

  const fetchResults = (lang, page) => {
    setLoading(true);
    communityService
      .getAllLikes(lang, page)
      .then((res) => res.json())
      .then((response) => {
        if (response && response.length > 0) {
          setOnLikes(onLikes.concat(response[0].entity));
        }
        setLoading(false);
      })
      .catch(console.log);

    return () => {
      setOnLikes([]);
    };
  };

  let data = Number(onLikes[0]);
  const { id } = JSON.parse(localStorage.getItem("me"));

  /**
   *
   * @param {*} e
   */
  const clickInteresting = (e) => {
    //Check server action in progress
    if (!liking) {
      setLiking(true);

      if (!liked) {
        //LIKE
        data++;
        communityService
          .like(post.id, "node", type)
          .then((result) => {
            communityService.notification(post.id, "like")
            communityService.postNotificationUser("like", null, result.id[0].value, id);
            setLikeId(result.id[0].value);
            setLiked(true);
            setLikes(likes + 1);
            setOnLikes(data);
            setLiking(false);
          })
          .catch((err) => {
            setLiking(false);
          });

        //DISLIKE
      } else {
        data--;
        communityService
          .dislike(likeId)
          .then((result) => {
            setLikeId(0);
            setOnLikes(data);
            setLiked(false);
            setLikes(likes - 1);
            setLiking(false);
          })
          .catch((err) => {
            setLiking(false);
          });
      }
    }

    e.preventDefault();
  };

  const clickComments = (e) => {
    e.preventDefault();
    setCommenting(!commenting);
  };

  const clickShares = (e) => {
    e.preventDefault();
  };

  return (
    <div className="feed__actions">
      {
        /* Buttons */
        actions && (
          <div className="comments__btns d-flex">
            {/* Likes */}
            <div className="interesting__btn align-items-center">
              {liked ? (
              <a
                href="/"
                className="d-flex align-items-center like-post"
                onClick={clickInteresting}
              >
                  <img
                    src={ ico_interesting_like }
                    alt="Mark as not cool"
                  />
                  <span>{liked ? "Cool" : "Cool"}</span>
                  </a>
                ) : (
                  <a
                  href="/"
                  className="d-flex align-items-center no-like-post"
                  onClick={clickInteresting}
                >
                  <img
                    src={ico_interesting}
                    alt="Mark as cool"
                  />
                  <span>{liked ? "Cool" : "Cool"}</span>
              </a>
                )}
                
            </div>

            {/* Comments */}
            <div className="interesting__btn align-items-center">
              <a
                href="/"
                className="d-flex align-items-center"
                onClick={clickComments}
              >
                <img src={ico_comments} alt="Reply to comment" />
                <span>{t("contents.comment-coontent")}</span>
              </a>
            </div>

            {
              /* Shares */
              like !== false && (
                <div className="interesting__btn align-items-center others__links dropdown feed">
                  <a
                    href="/"
                    className="d-flex align-items-center"
                    onClick={clickShares}
                  >
                    <img src={ico_toolbar} alt="Reply to comment" />
                    <span>{t("contents.share")}</span>
                  </a>
                  <ul
                    className="share__options dropdown-list"
                    id="shared__list"
                  >
                    <div className="share__links">
                      <WhatsappShareButton url={newUrl}>
                        <p>
                          <i className="fab fa-whatsapp" aria-hidden="true"></i>
                          {t("shared.share")} Whatsapp
                        </p>
                      </WhatsappShareButton>
                      <FacebookShareButton url={newUrl}>
                        <p>
                          <i
                            className="fab fa-facebook-f"
                            aria-hidden="true"
                          ></i>
                          {t("shared.share")} Facebook
                        </p>
                      </FacebookShareButton>
                      <LinkedinShareButton url={newUrl}>
                        <p>
                          <i
                            className="fab fa-linkedin-in"
                            aria-hidden="true"
                          ></i>
                          {t("shared.share")} Linkedin
                        </p>
                      </LinkedinShareButton>
                      <TwitterShareButton url={newUrl}>
                        <p>
                          <i className="fab fa-twitter" aria-hidden="true"></i>
                          {t("shared.share")} Twitter
                        </p>
                      </TwitterShareButton>
                    </div>
                  </ul>
                </div>
              )
            }
          </div>
        )
      }

      {
        /* Comments */
        commenting && (
          <Comments
            id={post.id}
            quantity={Number("0" + post.comments)}
            discussion={true}
          />
        )
      }
      {(Number("0" + likes) > 0 ||
        Number("0" + post.comments) > 0 ||
        Number("0" + post.comments) > 0 ||
        Number("0" + post.views) > 0 ||
        Number("0" + post.votes) > 0) && (
        <>
          {/* Summary */}
          <div className="reactions__counter">
            <div className="article__feedback d-flex justify-content-between">
              <div className="reactions__counter d-flex justify-content-between">
                {
                  /* Total Likes */
                  Number("0" + likes) > 0 && (
                    <div className="interesting__counter align-items-center">
                      <a
                        href="/"
                        className="d-flex align-items-center"
                        onClick={(e) => e.preventDefault()}
                      >
                        <img src={ico_interesting} alt="Mark as cool" />
                        <span>{onLikes ? onLikes : likes}</span>
                      </a>
                    </div>
                  )
                }

                {
                  /* Total Comments */
                  Number("0" + post.comments) > 0 && (
                    <>
                      <span className="article__cat__separator">|</span>
                      <div className="comment__counter align-items-center">
                        <a
                          href="/"
                          className="d-flex align-items-center"
                          onClick={clickComments}
                        >
                          <span>
                          <img src={ico_comments} alt="Mark as cool" /> {post.comments} {t("contents.comments-content")}
                          </span>
                        </a>
                      </div>
                    </>
                  )
                }

                {
                  /* Total Views */
                  Number("0" + post.views) > 0 && (
                    <>
                      <span className="article__cat__separator">•</span>
                      <div className="comment__counter align-items-center">
                        <a
                          href="/"
                          className="d-flex align-items-center"
                          onClick={(e) => e.preventDefault()}
                        >
                          <span>
                            {post.views} {t("contents.views")}
                          </span>
                        </a>
                      </div>
                    </>
                  )
                }
              </div>
              {
                /* Qualifications */
                qualification === true && Number("0" + post.votes) > 0 && (
                  <div className="max__rating d-flex myarticle__rating">
                    <div className="big__rate">
                      <img src={ico_bigstar} alt="Rate this" />
                    </div>
                    <div className="rate__numbers">
                      <span>{Number(post.votes).toFixed(1)} / 5</span>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </>
      )}
    </div>
  );
};

function mapState(state) {
  const { favorites } = state.users;
  return { favorites };
}

const connectedPostReactions = connect(mapState)(PostReactions);
export { connectedPostReactions as PostReactions };
