import React, { useState } from "react";
import { CategorySelect } from "../ui/category-select";
import { useTranslation } from "react-i18next";
import { useLocation } from "@reach/router";

export const useQuery = (queryParam) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam);
};

export const EventsForm = ({ onSubmit }) => {
  const { t } = useTranslation("common");
  const [inputDate, setInputDate] = useState('');

  const [filters, setFilters] = useState({
    q: useQuery("q") || "",
    d: useQuery("d") || "",
    c: useQuery("c") || "",
    b: useQuery("b") || "",
  });

  const changeWhat = (e) => {
    let data = { ...filters };
    data.q = e.target.value;
    setFilters(data);
  };

  const changeCategory = (e) => {
    let data = { ...filters };
    data.c = e.value;
    setFilters(data);
  };

  const changeDate = (e) => {
    setInputDate(e.target.value);
    let data = { ...filters };
    data.d = e.target.value;
    setFilters(data);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    onSubmit(filters);
  };

  return (
    <form
      className="d-flex flex-sm-column flex-md-row flex-lg-row form__events"
      onSubmit={handleSearch}
    >
      <div className="d-flex justify-content-center align-items-end contains__events">
        <div className="d-flex flex-column text-left vendor-camp">
          <label className="p-0">{t("events.name")}</label>
          <input
            type="text"
            placeholder={t("events.event_name")}
            value={filters.q}
            onChange={changeWhat}
          />
        </div>
        <div className="d-flex flex-column text-left vendor-camp category__events">
          <label className="p-0 no__floating">{t("events.category")}</label>
          <div className="form-label-group">
            <CategorySelect handler={changeCategory} />
          </div>
        </div>
        <div className="vendor-camp">
          <label className="no__floating">{t("events.date")}</label>
          <div className="form-label-group">
            <input
              type="date"
              id="birthdate"
              className="form-control"
              value={inputDate}
              onChange={changeDate}
            />
          </div>
        </div>
        <div className="events__button">
          <button className="btn btn-lg btn-primary btn-block" type="submit">
            {t("buttons.search")}
          </button>
        </div>
      </div>
    </form>
  );
};
