import { authHeader } from "../_helpers";
import { appConstants } from "../_constants";

export const articleService = {
  getArticleLatest,
  getCoursesGroup,
  getCourses,
  getInternalCourse,
  getInternalCoursePage,
  getArticleFeatured,
  getArticlePopular,
  getInfoArticle,
  getArticleFrenetics,
  getArticleSearch,
  getUserSearch,
  getGroupSearch,
  getVendorSearch,
  getUser,
  getArticleRelated,
  getSurvey,
  getSurveyResult,
  rateArticle,
  postSaveArticle,
  postUnSaveArticle,
  getListSaveArticles
};

function getArticleLatest(lang, category = "", page = 0) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/articles-latest?filters=yes${
      category ? `&category=${category}` : ""
    }${page ? `&page=${page}` : ""}`,
    requestOptions
  );
}


function getCoursesGroup(lang, page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/courses?page=${page}`,
    requestOptions
  );
}

function getCourses(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/courses`,
    requestOptions
  );
}

function getInternalCourse(lang, id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/internal-course/${id}`,
    requestOptions
  );
}

function getInternalCoursePage(lang, id, page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/internal-course-videos/${id}?page=${page}`,
    requestOptions
  );
}




function getArticleRelated(lang, category = "", tag = "[none]") {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/articles-related?filters=yes${
      category ? `&category=${category}` : ""
    }${tag ? `&tag=${tag}` : ""}`,
    requestOptions
  );
}

function getArticleFeatured(lang, category = "", topic = "") {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/articles-featured?filters=yes${
      category ? `&category=${category}` : ""
    }${topic ? `&topic=${topic}` : ""}`,
    requestOptions
  );
}

function getArticlePopular(lang, category = "") {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/articles-popular${
      category ? `?category=${category}` : ""
    }`,
    requestOptions
  );
}

function getArticleFrenetics(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/articles-frenetics`,
    requestOptions
  );
}

function getInfoArticle(lang, slug) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/article?alias=/${slug}`,
    requestOptions
  );
}

function getArticleSearch(lang, query, categories, page) {
  let cats = "";
  if (categories.length) {
    const separator = "&cat[]=";
    cats = separator + categories.join(separator);
  }

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/articles-search?title=${query}&body=${query}&page=${page}${cats}`,
    requestOptions
  );
}

function getUserSearch(lang, query) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/search-users?search=${query}`,
    requestOptions
  );
}

function getGroupSearch(lang, query) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/search-group?search=${query}`,
    requestOptions
  );
}

function getVendorSearch(lang, query) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/search-supplier?supplier=${query}`,
    requestOptions
  );
}

function getUser(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/group-user-list`,
    requestOptions
  );
}

function getSurvey(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  // return fetch(`${appConstants.API}/${lang}/v1/events-recent`, requestOptions);
  return fetch(`http://localhost:3000/preguntas`, requestOptions);
}

function getSurveyResult(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  // return fetch(`${appConstants.API}/${lang}/v1/events-recent`, requestOptions);
  return fetch(`http://localhost:3000/respuestas`, requestOptions);
}

function rateArticle(id, rate) {
  //Setup object according drupal's format.
  const payload = {
    type: [{ target_id: "vote" }],
    entity_id: [{ target_id: id }],
    value: [{ value: rate }],
  };

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(`${appConstants.API}/entity/vote?_format=json`, requestOptions);
}

function postSaveArticle (postId, uId) {

  let parsePostId = Number(postId);

  const payload = {
    entity_id: parsePostId,
    entity_type: "node",
    flag_id: "saved_articules",
    uid: uId,
    flag_action: "flag"
  }

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };

  return fetch(`${appConstants.API}/tech-flag/post`, requestOptions);
}

function postUnSaveArticle (postId, uId) {
  const payload = {
    entity_id: postId,
    entity_type: "node",
    flag_id: "saved_articules",
    uid: uId,
    flag_action: "unflag"
  }

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };

  return fetch(`${appConstants.API}/tech-flag/post`, requestOptions);
}

function getListSaveArticles (lang, uId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/saved-articles?uid=${uId}`,
    requestOptions
  );
}