import React, { useState, useEffect } from "react";
import { taxonomys } from "../../_services/taxonomys.service";
import { useTranslation } from "react-i18next";
import Select from 'react-select';

export const Subject = ({ value, label, handler }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [subjects, setSubject] = useState([]);
  const options = [];

  useEffect(() => {
    /** Call Services  */
    taxonomys
      .getSubject(lang)
      .then((res) => res.json())
      .then((response) => {
        setSubject(response);
      })
      .catch(console.log);
  }, [lang, setSubject]);

  subjects.map( item => {
    options.push({
      value: item.id,
      label: t(item.category),
    });
  });

  return (
    <div className="form-label-group subject no__floating">
      <label className="no__floating">{t("contact.consulting")}</label>
      <Select
        options={options}
        onChange={handler}
        placeholder={t("contact.select")}
      />
    </div>

  );
};
