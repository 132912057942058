import { authHeader } from "../_helpers";
import { appConstants } from "../_constants";

export const communityService = {
  getInternalPost,
  getWallContent,
  getWallContentGroup,
  getAllLikes,
  addPost,
  addImage,
  like,
  notification,
  notificationGroup,
  dislike,
  deleteArticle,
  updateStatus,
  postArticleGroup,
  postGroup,
  deleteComment,
  getLikesComment,
  postNotificationUser
  
};

function getInternalPost (lang, id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/articleid/${id}`,
    requestOptions
  );
}


function getWallContent(lang, group, page = 0) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/wall?filters=yes${
      group ? `&group=${group}` : ""
    }${page ? `&page=${page}` : ""}`,
    requestOptions
  );
}

function getWallContentGroup(lang, group) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${appConstants.API}/${lang}/v1/wall-public/${group}`,
    requestOptions
  );
}

function getLikesComment(lang, id, me) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `https://dev-techfrenetic.us.seedcloud.co/api/${lang}/v1/comment-like/?cid=${id}&user_id=${me}`,
    requestOptions
  );
}

function getAllLikes(lang, id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/likes-article/${id}`,
    requestOptions
  );
}

function addPost(lang, post) {
  //Setup object according drupal's format.
  const payload = {
    type: [{ target_id: "post", target_type: "node_type" }],
    title: [{ value: post.title }],
    langcode: [{ value: lang }],
  };

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(`${appConstants.API}/node?_format=json`, requestOptions).then(
    handleResponse
  );
}

/**
 *
 * @param {*} lang
 * @param {*} image
 * @param {*} filename
 * @param {*} data
 * @param {*} draft
 */
export const addArticle = async (
  lang,
  data,
  draft,
  id_video = null,
  photo,
  photo_name,
  photo_type,
  title_value,
  id
) => {
  const { title, tags, category, description, body } = data;
  let respTags = [];
  try {
    if (tags.length !== 0) {
      const payload = {
        tags: tags,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          ...authHeader(),
          "Content-Type": "application/hal+json",
        },
        body: JSON.stringify(payload),
        credentials: "same-origin",
      };
      const resp = await fetch(
        `${appConstants.API}/taxonomy/create/tags?_format=hal_json`,
        requestOptions
      );
      if (resp.status !== 200) {
        throw resp;
      }
      const listTag = await resp.json();
      listTag.map((r) => (
        respTags.push({
          target_id: r.tid,
        })
      ));
    }
    const imagen = await addImage(
      photo,
      photo_name,
      photo_type,
      title_value,
      id
    );

    //Article data
    const requestOptions = {
      method: "POST",
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: [{ target_id: "article", target_type: "node_type" }],
        title: [{ value: title }],
        langcode: [{ value: lang }],
        field_image: [{ target_id: imagen.fid[0].value, alt: title }],
        field_frenetic_content: [{ value: true }],
        field_main_category: [{ target_id: category }],
        field_draft: [{ value: false }],
        body: [{ value: body }],
        field_has_video: [{ value: id_video ? true : false }],
        field_cloudflare_id: [{ value: id_video }],
        field_summary: [{ value: description }],
        field_tag: !!respTags ? respTags : false,
        // field_post_type: "feed",
      }),
    };

    //Create article
    return await fetch(
      // `${appConstants.API}/${lang}/entity/node?_format=json`,
      `${appConstants.API}/${lang}/node?_format=json`,
      requestOptions
    );
  } catch (error) {
    // const resp = error;
    return handleResponse(error);
  }
};

/**
 *
 * @param {*} image
 * @param {*} data
 */
function addImage(photo, photo_name, photo_type, title_value, id) {
  //Image data
  const base64result = photo.split("base64,");

  const imageOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/hal+json",
    },
    body: JSON.stringify({
      _links: {
        type: {
          href: `http://${appConstants.LOCATION}/rest/type/file/image`,
        },
      },
      uri: [
        {
          value: `public://${photo_name}`,
        },
      ],
      filename: {
        value: photo_name,
      },
      filemime: {
        value: photo_type,
      },
      type: {
        target_id: "image",
      },
      data: [
        {
          value: base64result[1],
        },
      ],
      uid: [
        {
          target_id: id,
        },
      ],
      field_image_alt_text: [
        {
          value: title_value,
        },
      ],
    }),
  };

  //Upload photo
  return fetch(
    `${appConstants.API}/entity/file?_format=json`,
    imageOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} nid
 * @param {*} entity
 * @param {*} field
 */
function like(nid, entity, field) {
  //Image data
  const likeOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      type: [{ target_id: "like", target_type: "vote_type" }],
      entity_type: [{ value: entity }],
      entity_id: [{ target_id: nid }],
      value: [{ value: 1 }],
      value_type: [{ value: "percent" }],
      field_name: [{ value: field }],
    }),
  };

  //Upload photo
  return fetch(
    `${appConstants.API}/entity/vote?_format=json`,
    likeOptions
  ).then(handleResponse);
}

function notification(id, type) {
  const payload = {
    _links: {
      type: {
        href: `http://${appConstants.LOCATION}/rest/type/notification/notification`,
      },
    },
    name: [{ value: id }],
    field_content: [{ target_id: id }],
    field_type: [{ value: type }],
  };
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/hal+json",
    },
    body: JSON.stringify(payload),
    credentials: "same-origin",
  };

  fetch(
    `${appConstants.API}/entity/notification?_format=hal_json`,
    requestOptions
  );
}

function notificationGroup(id, type) {
  const payload = {
    _links: {
      type: {
        href: `http://${appConstants.LOCATION}/rest/type/notification/notification`,
      },
    },
    name: [{ value: id }],
    field_group: [{ target_id: id }],
    field_type: [{ value: type }],
  };

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/hal+json",
    },
    body: JSON.stringify(payload),
    credentials: "same-origin",
  };

  fetch(
    `${appConstants.API}/entity/notification?_format=hal_json`,
    requestOptions
  );
}

/**
 *
 * @param {*} nid
 */
function deleteArticle(nid) {
  //Image data
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
      "Content-Type": "application/hal+json",
    },
  };

  //Delete article
  return fetch(
    `${appConstants.API}/node/${nid}?_format=json`,
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} status
 * @param {*} nid
 */
function updateStatus(status, nid) {
  //Image data
  const requestOptions = {
    method: "PATCH",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      type: [{ target_id: "article", target_type: "node_type" }],
      field_draft: [{ value: status }],
    }),
  };

  //Delete article
  return fetch(
    `${appConstants.API}/node/${nid}?_format=json`,
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} nid
 */
function dislike(nid) {
  //Image data
  const likeOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  //Upload photo
  return fetch(
    `${appConstants.API}/entity/vote/${nid}?_format=json`,
    likeOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} id
 */

function deleteComment(id) {
  //Image data
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
      "Content-Type": "application/hal+json",
    },
    credentials: "same-origin",
  };

  //Upload photo
  return fetch(
    `${appConstants.API}/comment/${id}?_format=hal_json`,
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} response
 */
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        window.location.reload(true);
      }
      const error = (data && data.message) || response.statusText;

      //Filter Drupal errors.
      let result = String(error.toString());

      if (result.substr("unprocessable_entity_validation_failed") >= 0) {
        result = "unprocessable_entity_validation_failed";
        return Promise.reject(result);
      }

      return Promise.reject(result);
    }
    return data;
  });
}

/**
 *
 * @param {*} id
 * @param {*} group_id
 * @param {*} article_id
 */
function postArticleGroup(id, group_id, article_id) {
  //Image data
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/hal+json",
      // Authorization: "Basic dGZhZG1pbjphaWkyOFhHYUhMeGFTR1I=",
    },
    body: JSON.stringify({
      _links: {
        type: {
          href: `http://${appConstants.LOCATION}/rest/type/group_content/group-group_node-article`,
        },
      },
      type: [
        {
          target_id: "group-group_node-article",
          target_type: "group_content_type",
        },
      ],
      uid: [{ target_id: id }],
      gid: [{ target_id: group_id }],
      entity_id: [{ target_id: article_id }],
    }),
  };

  //Upload photo
  return fetch(
    `${appConstants.API}/entity/group_content?_format=hal_json`,
    requestOptions
  );
}

function postGroup(id, group_id, post_id) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/hal+json",
    },
    body: JSON.stringify({
      _links: {
        type: {
          href: `http://${appConstants.LOCATION}/rest/type/group_content/group-group_node-post`,
        },
      },
      type: [
        {
          target_id: "group-group_node-post",
          target_type: "group_content_type",
        },
      ],
      uid: [{ target_id: id }],
      gid: [{ target_id: group_id }],
      entity_id: [{ target_id: post_id }],
    }),
  };

  return fetch(
    `${appConstants.API}/entity/group_content?_format=hal_json`,
    requestOptions
  );
}


// New Service notification.
function postNotificationUser(type, id, nid, user_id ) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      field_type: type, // las opciones son follow, group, like, comment 
      id_1: id, // acá va el id del usuario que se sigue cuado es follow o  el id del comentario generado cuando es comment y se crea el comment
      nid: nid, // acá va el id del grupo que se unio osea cuando es group o el id del like generado cuando es like y se crea el like
      user_id: user_id // usuario logueado
    }),
    credentials: "same-origin",
  };

  return fetch(
    `${appConstants.API}/custom-notifications/post`,
    requestOptions
  );
}