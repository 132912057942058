import React, { useEffect, useState } from "react";
import { PostOwned } from "../ui/post-owned";
import { PostFeed } from "../ui/post-feed";
import { connect } from "react-redux";
import { PostQuote } from "../ui/post-quote";
import { PostSomething } from "../ui/post-something";
import { Spinner } from "../ui/spinner";
import { LoadMore } from "../contents/load-more";
import { communityConstants } from "../../_constants";
import { communityService } from "../../_services";
import { useTranslation } from "react-i18next";
import GroupsMayLike from "./groups-maylike";
import CommunityAd2 from "../ads/community-ad2";
import CommunityAdSingle from "../ads/community-ad-single";

const CommunityPosts = ({ section, user, group }) => {
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const fetchResults = (lang, page) => {
    setLoading(true);
    communityService
      .getWallContent(lang, null, page)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setTotal(response.results);
          setPosts(posts.concat(response.articles));
        }
        setLoading(false);
      })
      .catch(console.log);
  };

  useEffect(() => {
    fetchResults(lang, page);
  }, [lang, page]);

  const handleMore = () => {
    setPage(page + 1);
    fetchResults(lang, page + 1);
  };

  const onAddedMessage = (post) => {
    setTotal(total + 1);
    setPosts([post, ...posts]);
  };

  const onDeletePost = (post) => {
    setPosts(posts.filter((item) => item.id !== post.id));
  };

  const { name } = JSON.parse(localStorage.getItem("me"));

  // Función para renderizar un anuncio CommunityAd2
  const renderAd = (index) => {
    if (index % 3 === 0 && index !== 0) {
      return <CommunityAd2 key={`ad-${index}`} />;
    }
    return null;
  };

  return (
    <>
      <h3 className="feed-title">{t("feed.community")}</h3>
      <h1 className="welcome-user">
        {t("feed.welcome")}, {name}!
      </h1>
      <PostSomething onMessage={onAddedMessage} group={group} />
      <div className="groups__maylike">
        <GroupsMayLike />
      </div>

      <CommunityAdSingle />
      <h3 className="what-new">{t("feed.what-new")}</h3>
      <div className="feeds__community mb-6">
        {posts.map((item, i) => (
          <React.Fragment key={i}>
            {renderAd(i)}
            {item.type === "Post" && (
              <PostQuote
                post={item}
                key={i}
                onDelete={onDeletePost}
                user={user.current_user.name}
              />
            )}
            {item.type === "Article" && item.user === user.current_user.name && (
              <PostOwned
                post={item}
                key={item.id}
                onDelete={onDeletePost}
                user={user.current_user.name}
              />
            )}
            {item.type === "Article" && item.user !== user.current_user.name && (
              <PostFeed post={item} key={i} user={user.current_user.name} />
            )}
          </React.Fragment>
        ))}
      </div>
      <br />
      <br />
      <br />

      {loading ? (
        <Spinner />
      ) : (
        <LoadMore
          page={page}
          totalItems={total}
          itemsByPage={communityConstants.WALLPOSTS_BY_PAGE}
          onClick={handleMore}
        />
      )}
      <br />
      <br />
      <CommunityAdSingle />
    </>
  );
};

function mapState(state) {
  const { user } = state.authentication;
  return { user };
}

const connectedCommunityPosts = connect(mapState, null)(CommunityPosts);
export { connectedCommunityPosts as CommunityPosts };
