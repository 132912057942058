import React, { useEffect, useState } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { Link, navigate } from "@reach/router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { MobileMenu } from "../_components/navigation/mobile-menu";
import { SearchVendorForm } from "../_components/forms/search-vendor-form";
import ico_search_partners from "../_images/ico-search-partner-darkblue.svg";

import arrow_more from "../_images/ico-hfeatured-arrow-right.svg";

import { vendorService } from "../_services";

const VendorsPage = () => {
  const { t, i18n } = useTranslation("common");
  const [description, setDescription] = useState([]);
  const [vendors, setVendors] = useState([]);
  const lang = i18n.language;
  let result = vendors.slice(0, 3);

  //Receive submit on search.
  const handleSearchSubmit = (filters) => {
    let query = "";
    Object.keys(filters).map((idx) => {
      let param = "";
      if (filters[idx]) {
        //Translate
        switch (idx) {
          case "q":
            param = t("vendors.query_what");
            break;
          case "d":
            param = t("vendors.query_where");
            break;
          case "s":
            param = t("vendors.query_service");
            break;
          case "b":
            param = t("vendors.query_brand");
            break;
          default:
            param = "";
        }
        if (param) {
          query += !query
            ? `?${idx}=${filters[idx]}`
            : `&${idx}=${filters[idx]}`;
        }
      }
      return query;
    });

    if (query) {
      navigate(`/${lang}/${t("url.vendors")}/${t("url.search")}${query}`);
    }
  };

  useEffect(() => {
    /** Call Services  */
    vendorService
      .getVendorsList(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setVendors(response.rows);
        }
      })
      .catch(console.warn);

    return () => {
      setVendors([]);
    };
  }, [lang, setVendors]);

  useEffect(() => {
    /** Call Services  */
    vendorService
      .getVendorsDescription(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setDescription(response[0]);
        }
      })
      .catch(console.warn);

    return () => {
      setDescription([]);
    };
  }, [lang, setDescription]);

  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("vendors.title")} />
      <main
        className="home__page"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="partners__search__box">
          <div className="d-flex align-items-center">
            <div className="col-lg-12 partners__search__form">
              <h1>{t("vendors.title")}</h1>
              <p>{t("vendors.expand_intro")}</p>
              <div>
                <a href="/" className="form__partner__trigger">
                  <img src={ico_search_partners} alt={t("vendors.looking")} />
                  <span>{t("vendors.looking")}</span>
                </a>

                <SearchVendorForm
                  className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row align-items-center justify-content-center"
                  onSubmit={handleSearchSubmit}
                />
              </div>
            </div>
          </div>
        </section>
        {/** Body vendor */}
        <section className="body-vendors container">
          <div className="d-flex justify-content-center align-items-center gap-50 content-vendors">
            <div className="images-portada-vendors">
              <div className="d-flex ">
                <div className="section-1 d-flex justify-content-center align-items-center flex-column">
                  <img
                    src={description.field_image_one}
                    alt="v1"
                    className="img1-vendors"
                  />
                  <img
                    src={description.field_image_two}
                    alt="v2"
                    className="img2-vendors"
                  />
                </div>
                <img
                  src={description.field_image_three}
                  alt="v3"
                  className="img3-vendors"
                />
              </div>
            </div>
            <div className="text-body-vendors">
              <p>{description.body}</p>
            </div>
          </div>
        </section>

        {/** List vendor */}
        <section className="list-vendors container">
          <div className="list-category-cards  d-flex justify-content-center flex-column">
            <div className="category__card">
              <h2>
                <span>{t("vendors.our_newest")}</span>
                {t("vendors.label")}
              </h2>
            </div>
            <div className="cards_vendors d-flex justify-content-between">
              {result.map((i, index) => (
                <div key={index} className="card_vendor">
                  <Link  to={`/${lang}/${t("url.internal-vendor")}/${i.nid}`}>
                    <img src={i.field_photo} alt="vendors-image" />
                    <h2>{i.title}</h2>
                    <label>{i.field_services_vendors}</label>
                    <p>{i.body}</p>
                    <div className="vendor_more">
                      <p>{t("vendors.learn_more")}</p>
                      <img src={arrow_more} alt="arrow_more" />
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/** Contact vendor */}
        <section className="container contact-vendors">
          <div className="contact-vendor d-flex justify-content-center flex-column text-center">
            <h1>{t("vendors.text_footer")}</h1>
            <p>{t("vendors.text_footer_sub")}</p>
            <button>{t("vendors.btn_contact")}</button>
          </div>
        </section>
      </main>
      <MobileMenu selected="vendors" withFade={true} />
    </Layout>
  );
};

function mapState(state) {
  const { config } = state.app;
  if (config) {
    return { categories: config.categories };
  }

  return { categories: null };
}

const connectedVendorsPage = connect(mapState)(VendorsPage);
export { connectedVendorsPage as VendorsPage };
